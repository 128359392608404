<template>
  <div>
    <div class="navRight">
      <div class="navRight_header flex-sb">
        <p>{{ t('157') }}</p>
        <div class="flex-sb" style="height: 100%">
          <div class="icon_btn blue_btn" @click="exportData()" v-checkAuth="'data-version-export'">
            <el-icon class="element_icon"> <Upload /> </el-icon>&nbsp;{{ t('647') }}
          </div>
          <div class="icon_btn blue_btn" @click="importVersion" v-checkAuth="'data-version-import'">
            <el-icon class="element_icon"> <Download /> </el-icon>&nbsp;{{ t('648') }}
          </div>
          <div
            class="icon_btn red_btn"
            style="margin-top: 2px"
            @click="batchDel" v-checkAuth="'data-version-delete'"
          >
            <el-icon class="element_icon"> <Delete /> </el-icon>&nbsp;{{ t('210') }}
          </div>
        </div>
      </div>
      <div class="navRight_footer">
        <search-bar
          class="search"
          :searchPlaceholder="t('843')"
          width="19.625rem"
          v-model:searchContent="queryForm.queryInfo"
          @on-search="getData(true)"
        />
        <ele-select
          :label="t('844')"
          :options="typeList"
          :labelname="'desc'"
          :value="'value'"
          v-model:selectVal="typeList.value"
          @on-select="getData(true)"
        />
      </div>
    </div>
    <div class="container">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="tableData"
        v-model:pageNo="queryForm.pageNo"
        v-model:pageSize="queryForm.pageSize"
        :total="queryForm.total"
        @handleNoChange="getData"
        @handleSizeChange="getData"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:buttonHanlder>
          <el-table-column :label="t('610')" align="center">
            <template v-slot="{ row }">
              <span class="blue_btn" @click="removeTask(row)">{{ t('392') }}</span>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>
  </div>
  <import-version
    v-if="isVisible"
    v-model:isVisible="isVisible"
    :typeList="typeList"
    @versionInit="getData"
  ></import-version>
</template>

<script>
import { t } from '../../../languages';
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import download from "@/network/download";
import data from "@/network/data";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import importVersion from "./components/importVersion.vue";
export default defineComponent({
  data() {
    return {
      tableColumnArray: [
        { label: t("845"), prop: "versionName" },
        { label: t("846"), prop: "versionTypeName" },
        { label: t("635"), prop: "versionNum" },
        { label: t("412"), prop: "createTime" },
        { label: t("411"), prop: "builderName" },
      ],
    };
  },
  components: { importVersion },
  setup() {
    onMounted(() => {
      getData();
      getTypeList();
    });
    const state = reactive({
      queryForm: {
        taskId: "",
        queryInfo: "",
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
    });

    /* 获取任务列表 */
    const tableData = ref([]);
    async function getData(flag) {
      // 重置页数
      if (flag) {
        state.queryForm.pageNo = 1;
      }
      const res = await data.getVersionTable({
        queryInfo: state.queryForm.queryInfo,
        type: typeList.value.value,
        pageNo: state.queryForm.pageNo,
        pageSize: state.queryForm.pageSize,
      });
      if (res.resultStatus) {
        tableData.value = res.resultData.data;
        state.queryForm.total = res.resultData.total;
      }
    }
    /* 勾选Checkbox */
    const checkedIds = ref([]);
    function handleSelectionChange(selection) {
      const ids = [];
      for (let i = 0; i < selection.length; i++) {
        ids.push(selection[i].versionId);
      }
      checkedIds.value = ids;
    }

    /* 批量删除 */
    function batchDel() {
      if (checkedIds.value.length > 0) {
        ElMessageBox.confirm(t("847"), t("130"), {
          type: "warning",
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
        })
          .then(async () => {
            const res = await data.deleteVersionData({
              ids: checkedIds.value,
            });
            if (res.resultStatus) {
              ElMessage.success(t("560"));
              getData();
            }
          })
          .catch(() => {
            ElMessage(t("549"));
          });
      } else {
        ElMessage.warning(t("659"));
      }
    }
    //获取类型下拉列表
    const typeList = ref([]);
    const getTypeList = async () => {
      const res = await data.getTypeList();
      typeList.value = res.resultData.downTrees;
    };
    /*根据id移除任务 */
    const removeTask = async (row) => {
      let versionType = "";
      typeList.value.forEach((item) => {
        if (item.value === row.versionType) {
          versionType = item.desc;
        }
      });
      ElMessageBox.confirm(
        `${ t('848') }${versionType}${ t('849') }：${row.versionNum}${ t('850') }？`,
        t("130"),
        {
          type: "warning",
          confirmButtonText: t("218"),
          cancelButtonText: t("217"),
        }
      )
        .then(async () => {
          const ids = [];
          ids.push(row.versionId);
          const res = await data.deleteVersionData({ ids: ids });
          if (res.resultStatus) {
            ElMessage.success(t("565"));
            getData();
          }
        })
        .catch(() => {
          ElMessage(t("549"));
        });
    };
    //导出文件
    const exportData = async () => {
      if (checkedIds.value.length === 0) {
        openNotification(t("851"));
        return;
      }
      let ids = [...checkedIds.value];
      console.log(ids);
      await download.downloadSource(
        "/api/v1/export/version/excel",
        { ids: ids },
        "application/vnd.ms-excel",
        "post"
      );
    };
    //Notification框
    function openNotification(message, success) {
      ElNotification({
        title: t("130"),
        message,
        duration: 2000,
        type: success || "warning",
      });
    }
    /* 导入文件弹窗 */
    let isVisible = ref(false);
    function importVersion() {
      isVisible.value = true;
    }
    return {
      ...toRefs(state),
      getData,
      tableData,
      exportData,
      batchDel,
      handleSelectionChange,
      checkedIds,
      getTypeList,
      importVersion,
      typeList,
      removeTask,
      isVisible,
    };
  },
});
</script>

<style scoped lang="less">
.navRight {
  height: 6.5625rem;
  margin-left: 23.125rem;
  background: rgba(0, 28, 49, 0.8);
  padding: 1.0625rem 1.5625rem;

  .navRight_header {
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #33ddf7;
    }

    .element_icon {
      font-size: 1.125rem;
    }
  }

  .navRight_footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.9375rem;
    height: 40px;

    .search {
      display: flex;
      align-items: center;
    }

    .selection {
      background: transparent;
      border: none;
      color: #35e1fb;
      outline: 0;
      padding: 4px 5px;
      margin-left: 1rem;
      box-shadow: 0;
    }

    img {
      position: absolute;
      right: 0;
    }
  }
}

.container {
  border-top: 10px solid black;
}

//el-select的样式
.el-select {
  /deep/.el-input__wrapper {
    width: 23.125rem;
    border: 1px solid #36e5ff;
    border-radius: 0.25rem;
    background-color: @lightBackground;
    height: 2.5rem;
    box-shadow: 0 0 0 0;

    .el-input__inner {
      color: #fff;
    }
  }
}
</style>
