<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="'导入文件'"
    :dialogWidth="'504px'"
    :dialogTop="'506px'"
    :hasFooter="!isLoading"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="confirm(uploadFormRef)"
  >
    <el-form
      label-position="top"
      class="fileForm"
      :rules="fileRules"
      ref="uploadFormRef"
      :model="fileData"
    >
      <el-form-item :label="t('1201')" prop="versionName">
        <el-input :placeholder="t('780')" v-model.trim="versionName" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item :label="t('817')" prop="versionType">
        <el-select clearable v-model="versionType" :placeholder="t('1200')">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.desc"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('635')" prop="versionNum">
        <el-input :placeholder="t('1219')" v-model="versionNum"></el-input>
      </el-form-item>
      <div class="deleteFile" @click="deleteFile" v-show="fileName !== ''">
        <el-icon color="red"><Delete></Delete></el-icon>
        <span>&nbsp;{{ t('210') }}</span>
      </div>
      <el-form-item :label="t('1220')" prop="fileUpload">
        <div style="width: 100%">
          <el-upload
            class="upload-demo"
            action=""
            :auto-upload="false"
            :on-change="selectFile"
            :limit="1"
            accept=".apk"
            :http-request="httpUploadFile"
            ref="uploadRef"
            v-show="fileName === ''"
            :file-list="fileUpload"
            v-model="fileUpload"
            :show-file-list="false"
            ><span>{{ t('778') }}</span>
          </el-upload>
          <div v-show="fileName !== ''" class="fileShow">
            <img src="@/asset/img/data-mange/uploadFile.png" />

            {{ fileName }}
            <el-progress
              v-show="isLoading"
              :percentage="value"
              color="#67c23a"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="isLoading" class="lastClose">
        <el-button
          class="common-confirm-btn confirm_btn closed"
          size="large"
          @click="closeDialog"
          >{{ t('408') }}</el-button
        >
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../../languages';
import eleDialog from "@/components/package/ele-dialog.vue";
import { ElMessage, ElNotification, ElMessageBox } from "element-plus";
import { defineComponent, reactive, ref, toRefs } from "vue";
import SparkMD5 from "spark-md5";
import common from "@/network/common";
import dataService from "@/network/data";
export default defineComponent({
  components: { eleDialog },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["versionInit", "update:isVisible"],
  setup(prpos, { emit }) {
    /* 导入 */
    let fileData = reactive({
      versionType: "", //选择文件
      elForm: {},
      fileName: "", //文件名称
      versionName: "",
      versionNum: "",
      fileExist: false,
      fileMd5: "",
      fileUpload: [],
      value: 0, // 进度条
      isLoading: false, //进度条显示
      url: "", //文件上传后返回url
    });
    let fileReader = null; //文件读取流对象
    let notification = null;
    let fileSliceList = []; //存放文件分块数组
    let finishReqNum = 0; //文件分块数量
    let saveTime = 0; //保存变量
    const uploadFormRef = ref(null);
    const uploadRef = ref(null);

    /* 检查文件是否上传 */
    function doIncrementalTest(uploadFile) {
      // 清除正在读取的文件流和Notification 通知
      if (fileReader !== null) {
        fileReader.abort();
      }
      if (notification !== null) {
        notification.close();
      }
      //这里需要用到File的slice( )方法，以下是兼容写法
      var blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice,
        file = uploadFile,
        chunkSize = 5242880, // 以每片5MB大小来逐次读取
        chunks = Math.ceil(file.size / chunkSize), //分多少块
        currentChunk = 0,
        spark = new SparkMD5(); //创建SparkMD5的实例

      fileReader = new FileReader();
      notification = ElNotification({
        title: t("130"),
        message: t("1204"),
        duration: 0,
      });
      fileReader.onload = async function (e) {
        spark.appendBinary(e.target.result); // append array buffer
        currentChunk += 1;
        if (currentChunk < chunks) {
          loadNext(); //把分的块加到数组
        } else {
          fileData.fileMd5 = spark.end();
          // 检查md5存在与否
          let url = "/api/v1/file/version/";
          const data = await common.checkFileMD5(url, fileData.fileMd5);
          if (data instanceof Object && data.resultData.finished === true) {
            fileData.fileExist = true;
            fileData.url = data.resultData.url;
          } else {
            fileData.fileExist = false;
          }
          notification.close();
          notification = null;
        }
      };

      //文件读取失败
      fileReader.onerror = function () {
        console.log("something went wrong");
      };

      //将文件分块，数据添加到数组
      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        const tmp = blobSlice.call(file, start, end);
        tmp.position = start;
        fileSliceList.push(tmp);
        fileReader.readAsBinaryString(tmp);
      }
      loadNext();
    }

    //选择文件
    // let fileUpload = ref([]);
    function selectFile(file) {
      saveTime = 0;
      uploadFormRef.value.validateField("fileUpload", () => {});
      if (file.status !== "success") {
        if (file.raw) {
          doIncrementalTest(file.raw);
        }
        const name = file.name;
        if (name !== "") {
          fileData.fileName = name;
          const res = name.split(".");
          fileData.versionName = res[0];
        } else {
          file = {};
        }
      }
    }
    //删除选择文件
    function deleteFile() {
      removeFile();
      uploadFormRef.value.validateField("fileUpload", () => {});
    }
    // 自定义上传，判断直接保存或切片上传
    async function httpUploadFile(option) {
      fileData.isLoading = true;
      if (typeof XMLHttpRequest === "undefined") {
        return;
      }
      if (fileData.fileExist === true) {
        // 文件已存在，直接保存
        dataService
          .saveVersion({
            versionUrl: fileData.url,
            versionMd5: fileData.fileMd5,
            versionName: fileData.versionName,
            versionType: fileData.versionType,
            versionNum: fileData.versionNum,
          })
          .then((res) => {
            fileUploadSuccess(res.resultStatus);
          });
      } else {
        const requestParamList = [];
        fileSliceList.forEach((currentValue, index) => {
          let params = {
            chunkIndex: index,
            chunkNum: fileSliceList.length,
            fileName: fileData.fileName,
            md5: fileData.fileMd5,
            size: option.file.size,
            file: currentValue,
          };
          requestParamList.push(params);
        });
        requestUpload(requestParamList);
      }
    }
    /* 文件上传 */
    function requestUpload(requestParamList) {
      dataService
        .importVersion(requestParamList[finishReqNum])
        .then((res) => {
          if (res.resultStatus === true) {
            // 非200状态码 进行上传重试
            finishReqNum++;
            fileData.value = Number(
              ((finishReqNum / requestParamList.length) * 100).toFixed(2)
            );
          } else {
            ElMessage.info(t("1206"));
          }
          if (finishReqNum === requestParamList.length) {
            // 代表所有请求完成
            if (res.resultData.finished === true) {
              let params = {
                versionUrl: res.resultData.url,
                versionMd5: res.resultData.md5,
                versionName: fileData.versionName,
                versionType: fileData.versionType,
                versionNum: fileData.versionNum,
              };
              // 上传成功
              requestSave(params);
            } else {
              // 上传失败
              ElMessage.error(t("1207"));
            }
          } else {
            if (res.resultStatus === true) {
              if (fileData.isLoading) {
                // 上传状态中才可继续上传，否则为取消上传
                requestUpload(requestParamList);
              }
            } else {
              // 上传失败
              ElMessage.error(t("1208"));
            }
          }
        })
        .catch((res) => {
          finishReqNum++;
          ElMessage.error(t("1209"));
          console.log(t("1210") + res);
        });
    }

    //文件上传成功进度条
    function fileUploadSuccess(resultStatus) {
      if (resultStatus === true) {
        ElMessage.success(t("751"));
        emit("update:isVisible", false);
        emit("versionInit");
        fileData.versionType = "";
        fileData.versionName = "";
        fileData.fileName = "";
        // 进度条为0
        fileData.value = 0;
        fileData.isLoading = false;
        fileData.fileUpload = [];
      } else {
        emit("update:isVisible", false);
        ElMessage.error(t("1221"));
      }
    }
    //上传后保存图层
    function requestSave(params) {
      saveTime++;
      dataService.saveVersion(params).then((res) => {
        if (res.resultStatus === true) {
          ElMessage.success(t("792"));
        } else {
          if (saveTime < 3) {
            alert(t("225"));
            requestSave();
          }
          ElMessage.error(t("1222") + res.errorMessage);
        }
        fileUploadSuccess(res.resultStatus);
      });
    }
    //清除正在上传
    function removeFile() {
      fileData.fileName = "";
      fileData.versionName = "";
      fileData.fileUpload = [];
      // 清除正在读取的文件流和Notification 通知
      if (fileReader !== null) {
        fileReader.abort();
      }
      if (notification !== null) {
        notification.close();
      }
      finishReqNum = 0;
      fileSliceList = [];
    }
    /* 上传中关闭弹窗 */
    function closeDialog() {
      ElMessageBox.confirm(
        t("1223"),
        t("130"),
        {
          confirmButtonText: t("1213"),
          cancelButtonText: t("1214"),
          distinguishCancelAndClose: true,
        }
      )
        .then(() => {
          ElMessage(t("1215"));
          emit("update:isVisible", false);
        })
        .catch((action) => {
          if (action === "cancel") {
            fileData.isLoading = false;
            ElMessage(t("1216"));
            emit("update:isVisible", false);
            removeFile();
          }
        });
    }

    //表单校验
    const fileRules = reactive({
      versionName: [
        {
          required: true,
          message: t("780"),
          trigger: ["blur", "change"],
        },
      ],
      versionType: [
        {
          required: true,
          message: t("1200"),
          trigger: "change",
        },
      ],
      versionNum: [
        {
          required: true,
          message: t("1219"),
          trigger: ["blur", "change"],
        },
      ],
      fileUpload: [
        {
          required: true,
          message: t("1224"),
          trigger: ["blur", "change"],
        },
      ],
    });
    //确认导入
    async function confirm(formEl) {
      formEl.validate(async (valid) => {
        if (valid && notification === null && fileData.fileMd5 !== "") {
          await uploadRef.value.submit();
        } else if (notification !== null) {
          ElMessage.warning(t("1218"));
          return false;
        } else {
          // fileData.fileMd5 === "" &&
            // ElMessage.warning(t("1225"));
          return false;
        }
      });
    }
    return {
      ...toRefs(fileData),
      fileData,
      selectFile,
      deleteFile,
      closeDialog,
      fileRules,
      confirm,
      uploadRef,
      uploadFormRef,
      httpUploadFile,
      removeFile,
    };
  },
});
</script>

<style lang="less" scoped>
/deep/.fileForm {
  .el-form-item__label {
    color: #36e5ff;
  }
  .el-upload,
  .fileShow {
    width: 100%;
    height: 113px;
    background: #001c31;
    border: 1px solid #51d2ff;
  }
  .el-select {
    width: 100%;
    --el-select-border-color-hover: none;
    --el-select-input-focus-border-color: none;
  }
  .el-input__wrapper {
    background: #001c31;
    height: 40px;
    box-shadow: 0 0 0 0;
    border: 1px solid #36e5ff;
  }
  .deleteFile {
    position: absolute;
    right: 20px;
    .el-icon {
      vertical-align: middle;
    }
    cursor: pointer;
  }
  .fileShow {
    text-align: center;
    padding-top: 1.5rem;
    img {
      display: block;
      margin: auto;
    }
  }
  .el-progress__text {
    line-height: 0;
  }
  .closed {
    background: #00959b;
    border-color: #00f5ff;
    color: #ffffff;
    padding: 0 30px;
  }
  .lastClose .el-form-item__content {
    justify-content: end;
    margin-top: 2.875rem;
    margin-bottom: 1.1875rem;
  }
}
/deep/.el-input__inner {
  color: #ffffff;
}
</style>
